import { gql } from '@apollo/client';

export const PROJECT_DATA_TO_RETRIEVE = `
      vrConfig
`;

export const GET_PROJECT_INFO = gql`
  query getProjectInfoByHost ($host :String!, $url:String) {
    getProjectInfoByHost (host :$host, url:$url) {
      ${PROJECT_DATA_TO_RETRIEVE}
    }
  }`;

export const GET_PROJECT_INFO_DEFAULT_OPTIONS = hostname => {
  return {
    variables: {
      host: hostname,
      url: window.location.href,
    },
  };
};
