import React, { useState, useEffect } from 'react';
import { useQuery } from '@apollo/react-hooks';
import './App.scss';
import VirtualTour from './components/VirtualTour';
import DeviceSelection from './components/DeviceSelection';
import WebSocketWrapper from './wrappers/WebSocketWrapper';
import axios from 'axios';
import { Route, useHistory } from 'react-router-dom';
import Client from './client/Client';
import DetectUrl from './utils/DetectUrl';
import vars from 'config/vars';
import inizializeAnalytics from 'utils/analytics';

const tagManagerArgs = {
  gtmId: process.env.REACT_APP_GOOGLE_TAG_MANAGER_CODE,
};

inizializeAnalytics(tagManagerArgs);

function App(props) {
  // variabili di stato
  const [constants, setConstants] = useState(props._constants);
  const [customerId, setCustomerId] = useState(undefined);

  const [backgroundVR, setBackgroundVR] = useState(
    vars.easy360BaseUrl + '/Uploads/placeholder.jpg'
  );
  const [menuActive, setMenuActive] = useState(false);
  const [rotationVR, setRotationVR] = useState('0 0 0');

  const [connected, setConnected] = useState(false);
  const [connectionId, setConnectionId] = useState('');
  const [pairedDevice, setPairedDevice] = useState('');
  const [devicesFound, setDevicesFound] = useState([]);
  const [deviceName, setDeviceName] = useState(Math.floor(Date.now()));
  const [messageToSend, setMessageToSend] = useState('');

  // nello stato ho tutti i riferimenti agli assets del cliente
  const [tourData, setTourData] = useState({
    loading: false,
    currentGallery: '',
    images: [],
  });

  const prjInfo = useQuery(
    Client.GET_PROJECT_INFO,
    Client.GET_PROJECT_INFO_DEFAULT_OPTIONS(DetectUrl())
  );

  React.useEffect(() => {
    if (prjInfo.data) {
      if (
        prjInfo.data.getProjectInfoByHost &&
        prjInfo.data.getProjectInfoByHost.vrConfig &&
        prjInfo.data.getProjectInfoByHost.vrConfig.customerId
      )
        setCustomerId(prjInfo.data.getProjectInfoByHost.vrConfig.customerId);
    }
  }, [prjInfo.data]);

  // variabili di supporto
  let discoveryInterval;
  const discoveryTimeout = 5000;

  const addDeviceMsg = {
    type: 'addDevice',
    payload: {
      customer: customerId,
      clientName: deviceName,
      clientType: 'WEB',
    },
  };
  const listDeviceMsg = {
    type: 'listDevice',
    payload: {
      customer: customerId,
      clientType: 'VR',
    },
  };
  var pairDeviceMsg = {
    type: 'pairDevice',
    payload: {
      id: '',
    },
  };

  const history = useHistory();

  ////////////////////////////////////////

  useEffect(() => {
    // aggiorno dati da server remoto
    if (customerId) {
      setTourData({
        ...tourData,
        loading: true,
      });

      axios
        .get(vars.easy360BaseUrl + '/api/galleries/usergalleries/' + customerId)
        .then(response => {
          // ho acquisito folders e galleries
          // per ogni galleria/tour, prendo le immagini e mi salvo tutto.. tanto ho poi i riferimenti foto-galleria
          if (response.data && response.data.length > 0) {
            getGalleryImages(response.data[0].Code);
          }
        })
        .catch(() => {
          // se non sono riuscito a collegarmi al server, verifico se avevo memorizzato in localstorage i dati la sessione precedente
          // verifico se ho nel localstorage i dati già scaricati. se non li ho, chiamo API
          getConfigFromLocalstorage();
          setTourData({
            ...tourData,
            loading: false,
          });
        });
    }
  }, [tourData.isLoading, customerId]);

  function getGalleryImages(galleryCode) {
    setTourData({
      ...tourData,
      loading: true,
    });

    let remoteImages = [];

    // acquisisco le immagini della gallery specifica
    axios
      .get(
        vars.easy360BaseUrl + '/api/gallery/getphotosingallery/' + galleryCode
      )
      .then(response => {
        remoteImages = response.data;
        // creo oggetto che salvo in localstorage
        const localData = {
          currentGallery: galleryCode,
          images: remoteImages,
        };
        localStorage.setItem('tourData', JSON.stringify(localData));
        //salvo nello stato tutti i dati raccolti, e imposto il flag per indicare che ho finito
        setTourData({
          currentGallery: galleryCode,
          images: remoteImages,
          loading: false,
        });

        // imposta il primo sfondo
        const image = localData.images[0];
        const imagePath =
          vars.easy360BaseUrl +
          '/Uploads/Galleries/' +
          image.GalleryCode +
          '/md_' +
          image.PhotoPath;
        setBackgroundVR(imagePath);
        setRotationVR(`0 ${image.InitialOffset - 180} 0`);
      })
      .catch(() => {
        getConfigFromLocalstorage();
      });
  }

  function getConfigFromLocalstorage() {
    if (localStorage.getItem('tourData')) {
      // prendo i dati dal localstorage
      const data = localStorage.getItem('tourData');
      const structuredData = JSON.parse(data);
      setTourData({
        currentGallery: structuredData.galleries,
        images: structuredData.images,
        loading: false,
      });
      // imposta il primo sfondo
      const image = structuredData.images[0];
      const imagePath =
        vars.easy360BaseUrl +
        '/Uploads/Galleries/' +
        image.GalleryCode +
        '/md_' +
        image.PhotoPath;
      setBackgroundVR(imagePath);
    } else {
      // NON HO DATI DA VISUALIZZARE
    }
  }

  function onMessageReceived(message) {
    // scatenata ogni volta che ricevo un messaggio via websocket

    //console.log(message.type);

    // il client web è stato aggiunto alla lista delle connessioni attive
    if (message.type === 'deviceAdded') {
      setConnectionId(message.payload.connectionId);
      discoveryInterval = setInterval(discoveryVr, discoveryTimeout);
    }

    // il server ha inviato l'elenco dei dispositivi VR connessi
    if (message.type == 'deviceFound') {
      if (Array.isArray(message.payload) && message.payload.length > 0) {
        //if (!devicesFound.includes(message.payload)) {
        let dev = [];
        message.payload.map(el => {
          dev.push(el);
        });
        setDevicesFound(dev);
        //}
      }
      setMessageToSend('');
    }

    // il server ha risposto al tentativo di pairing. l'id potrebbe essere vuoto nel caso il dispositivo si sia disconnesso proprio in quell'istante
    if (message.type == 'devicePaired') {
      setPairedDevice(message.payload.id);
      console.log('Paired to device -> ' + message.payload.id);
      setMessageToSend('');
      clearInterval(discoveryInterval);
    }

    // il device VR si è disconnesso
    if (message.type == 'deviceDisconnected') {
      setPairedDevice('');
      setDevicesFound([]);
      history.push('/');
      discoveryInterval = setInterval(discoveryVr, discoveryTimeout);
    }

    // messaggio che gestisce la rotazione dell'immagine in base a quella del visore
    if (message.type === 'setRotation') {
      let rot = message.payload.rotation.split(' ');
      //console.log(message.payload.rotation);
      setRotationVR(`${-rot[0]} ${-rot[1] + 180} 0`);
    }

    // imposta immagine sul client web
    if (message.type === 'setClientImage') {
      if (tourData.isLoading) {
        setTimeout(function () {
          // do nothing. probabilmente è arrivato il messaggio di cambio gallery e sta ancora scaricando l'array aggiornato
        }, 2000);
      }
      tourData.images
        .filter(img => img.Code === message.payload.value)
        .map(filteredImg => {
          let fullPath =
            vars.easy360BaseUrl +
            '/Uploads/Galleries/' +
            filteredImg.GalleryCode +
            '/md_' +
            filteredImg.PhotoPath;
          setBackgroundVR(fullPath);
        });
    }

    // riceve da visore il cambio gallery. aggiorna array con immagini di quel tour
    if (message.type === 'setClientGallery') {
      getGalleryImages(message.payload.value);
    }
  }

  function onConnectionChanged(connected) {
    // scatenata al cambio di stato della connessione
    // lo stato viene usato per gestire il mouseDrag sulla foto. se non sono connesso, è consentito il drag. altrimenti viene bloccato.
    console.log('Connection changed -> ' + connected);
    setConnected(connected);
    if (connected) {
      setMessageToSend(JSON.stringify(addDeviceMsg));
    } else {
      clearInterval(discoveryInterval);
      setMessageToSend('');
      setPairedDevice('');
      setDevicesFound([]);
    }
  }

  function discoveryVr() {
    setMessageToSend(JSON.stringify(listDeviceMsg));
  }

  function pairDevice(deviceId) {
    pairDeviceMsg.payload.id = deviceId;
    setMessageToSend(JSON.stringify(pairDeviceMsg));
  }

  //////////////////////////////////////////
  // HTML

  return (
    <div className="App">
      {customerId && (
        <WebSocketWrapper
          onMessageReceived={onMessageReceived}
          messageToSend={messageToSend}
          onConnectionChanged={onConnectionChanged}
        />
      )}

      <Route
        path="/vr"
        render={props => (
          <VirtualTour
            {...props}
            backgroundVR={backgroundVR}
            setBackgroundVR={setBackgroundVR}
            rotationVR={rotationVR}
            setRotationVR={setRotationVR}
            pairedDevice={pairedDevice}
            setDevices={setDevicesFound}
            menuActive={menuActive}
            setMenuActive={setMenuActive}
            config={tourData}
            setMessageToSend={setMessageToSend}
          ></VirtualTour>
        )}
      />

      <Route
        path="/"
        exact
        // component={() => <DeviceSelection unpairDevice={unpairDevice} />}
        render={props => (
          <DeviceSelection
            {...props}
            pairDevice={pairDevice}
            devicesFound={devicesFound}
          />
        )}
      />
    </div>
  );
}

export default App;
