import React, { Component } from 'react';
import 'aframe';
import { Entity, Scene } from 'aframe-react';

class Photo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      rotationVR: this.props.rotationVR,
      background: this.props.backgroundVR,
      pairedDevice: this.props.pairedDevice,
    };
    this.panImage = React.createRef();
  }

  componentDidUpdate(prevProps) {
    // Utilizzo tipico (non dimenticarti di comparare le props):
    if (this.props.rotationVR !== prevProps.rotationVR) {
      //console.log("set rotation", this.props.rotationVR);
      var camera = document.querySelector('a-camera');
      camera.components['look-controls'].pitchObject.rotation.x = 0;
      camera.components['look-controls'].yawObject.rotation.y = 0;
      this.setState({ rotationVR: this.props.rotationVR });
    }
    if (this.props.backgroundVR !== prevProps.backgroundVR) {
      this.setState({ background: this.props.backgroundVR });
    }
    if (this.props.pairedDevice !== prevProps.pairedDevice) {
      this.setState({ pairedDevice: this.props.pairedDevice });
    }
  }

  handleClick = () => {
    console.log('Clicked!');
  };

  render() {
    const divStyleNoDrag = {
      display: this.state.pairedDevice !== '' ? 'block' : 'none',
    };
    const divStyleDrag = {
      display: this.state.pairedDevice !== '' ? 'none' : 'block',
    };

    return (
      <>
        <div style={divStyleNoDrag}>
          <Scene loading-screen="dotsColor: #ef2d5e; backgroundColor: #000000;">
            <Entity
              //events={{ click: this.handleClick }}
              ref={this.panImage}
              primitive="a-sky"
              scale={{ x: -0.9 }}
              src={this.state.background}
            />
            <Entity
              primitive="a-camera"
              rotation={this.state.rotationVR}
              look-controls={{ enabled: false, reverseMouseDrag: false }}
            />
          </Scene>
        </div>
        <div style={divStyleDrag}>
          <Scene loading-screen="dotsColor: #ef2d5e; backgroundColor: #000000;">
            <Entity
              //events={{ click: this.handleClick }}
              ref={this.panImage}
              primitive="a-sky"
              scale={{ x: -0.9 }}
              src={this.state.background}
            />
            <Entity
              primitive="a-camera"
              rotation={this.state.rotationVR}
              look-controls={{ enabled: true, reverseMouseDrag: true }}
            />
          </Scene>
        </div>
      </>
    );
  }
}

export default Photo;
